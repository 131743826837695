// project imports
import config from "../config";

// action - state management
import * as actionTypes from "./actions";

// Load initial state from localStorage, if available
const loadStateFromLocalStorage = () => {


  const savedState = localStorage.getItem("persist:customization");

  if (savedState) {
    return JSON.parse(savedState);
  }
  return {
    isOpen: [],
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true,
    selected_switch: "multiMeter",
    selected_hvac_switch: "chiller",
  };
};

export const initialState = loadStateFromLocalStorage();

//-----------------------|| CUSTOMIZATION REDUCER ||-----------------------//

const customizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      const id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    case actionTypes.UPDATE_SWITCH:
      return {
        ...state,
        selected_switch: action.payload,
      };
    case actionTypes.UPDATE_HVAC_SWITCH:
      return {
        ...state,
        selected_hvac_switch: action.payload,
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    case actionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily,
      };
    case actionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
    default:
      return state;
  }
};

export default customizationReducer;
