import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import reducer from "./reducer";
import { applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import storage from "redux-persist/lib/storage"; // Use localStorage by default

// Persist configuration
const persistConfig = {
    key: "customization",
    storage, // Default is localStorage
    whitelist: ["customization"], // Persist only the customization reducer (optional)
};
// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, reducer);

//-----------------------|| REDUX - MAIN STORE ||-----------------------//

// Create the store with persisted reducer
const store = createStore(persistedReducer, applyMiddleware(thunkMiddleware));
// Create the persistor to manage rehydration and persistence
const persistor = persistStore(store);

// Export the store and persistor
export { store, persistor };
