import {
  bms_dashboard,
  useTempDashboard,
  // ems_dashboard,
  useEmsDashboard,
  useDgDashboard,
  fms_dashboard,
  hvc_dashboard,
  vib_dashboard,
  useWmsDashboard,
  nbs_dashboard,
  trans_dashboard,
  superAdmin_dashboard,
  support_dashboard,
  useAdminDashboard,
} from "./ems_dashboard";

// Custom hook to generate menu items
export const useMenuItems = () => {

  const adminDashboard = useAdminDashboard();
  const emsDashboard = useEmsDashboard();
  const wmsDashboard = useWmsDashboard();
  const tempDashboard = useTempDashboard();
  const dgDashboard = useDgDashboard();

  return {
    items: [emsDashboard],
    dg_items: [dgDashboard],
    bms_items: [bms_dashboard],
    wms_items: [wmsDashboard],
    temp_items: [tempDashboard],
    fms_items: [fms_dashboard],
    hvc_items: [hvc_dashboard],
    vib_items: [vib_dashboard],
    nbs_items: [nbs_dashboard],
    trans_items: [trans_dashboard],
    superAdmin_items: [superAdmin_dashboard],
    admin_items: [adminDashboard],
    support_items: [support_dashboard],
  };
};
